.with-pointer{
    cursor: pointer;
}

.only-print{
    display: none;
}

@media print{
    .no-print{
        display: none !important;
    }
    .only-print{
        display: block !important;
    }

    .table-striped tr:nth-child(even) td {
        background-color: #dfdfdf !important;
        -webkit-print-color-adjust: exact;
    }
}